<template>
  <div class="container">
    <div class="py-4">
      <a href="#" @click="$router.go(-1)">
        Back
      </a>
    </div>
    <div class="row">
      <div class="col-12 mb-5 mt-5">
        <p dir="ltr">
          IMPORTANT PRIVACY INFORMATION
        </p>
        <br/>
        <p dir="ltr">
          In order to use the service, we will ask you to enter your gender, age
          category and email. We also automatically collect from your device language
          settings, IP address, time zone, type and model of a device, device
          settings, operating system, Internet service provider, mobile carrier,
          hardware ID, and other unique identifiers (such as IDFA and AAID). We need
          this data to provide our services, analyze how our customers use the
          service, to serve ads. For improving our service and serving ads, we use
          third party solutions. As a result, we may process data using solutions
          developed by Appsflyer, Facebook, Google, Amplitude, Firebase, Mailfire,
          Snapchat. Some of the data is stored and processed on servers of such third
          parties. This enables us to: (1) analyze different interactions (how often
          users make purchases, what is the average time spent by users on the
          website); (2) serve and measure ads (and are able to show them only to a
          particular group of users, for example, to users that have already visited
          the website). Consequently, we, in particular, better understand in what of
          our features and content you see the most value and are able to focus on
          them to enhance your experience and increase the quality of our products.
          Please read our Privacy Policy below to know more about what we do with
          data (Section 2), what data privacy rights are available to you (Section
          5), who will be the data controller (Section 12), and how to influence
          personalized advertising (Subsection 2.7). If any questions will remain
          unanswered, please contact us at support@mathmaster.me.
        </p>
        <br/>
        <p dir="ltr">
          PRIVACY POLICY
        </p>
        <br/>
        <p dir="ltr">
          This Privacy Policy explains what personal data is collected when you use
          the Expert Chat website (the “Website”) and the services provided through
          them (together with the Website , the “Service”), how such personal data
          will be used, shared. BY USING THE SERVICE, YOU PROMISE US THAT (I) YOU
          HAVE READ, UNDERSTAND AND AGREE TO THIS PRIVACY POLICY, AND (II) YOU ARE
          OVER 16 YEARS OF AGE (OR HAVE HAD YOUR PARENT OR GUARDIAN READ AND AGREE TO
          THIS PRIVACY POLICY FOR YOU). If you do not agree, or are unable to make
          this promise, you must not use the Service. In such case, you must contact
          us and request deletion of your data. “GDPR” means the General Data
          Protection Regulation (EU) 2016/679 of the European Parliament and of the
          Council of 27 April 2016 on the protection of natural persons with regard
          to the processing of personal data and on the free movement of such data.
          “EEA” includes all current member states to the European Union and the
          European Economic Area. “Process”, in respect of personal data, includes to
          collect, store, and disclose to others.
        </p>
        <br/>
        <p dir="ltr">
          TABLE OF CONTENTS
        </p>
        <br/>
        <p dir="ltr">
          1. CATEGORIES OF PERSONAL DATA WE COLLECT
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          2. FOR WHAT PURPOSES WE PROCESS PERSONAL DATA
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          3. UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA (Applies only to
          EEA-based users)
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          4. WITH WHOM WE SHARE YOUR PERSONAL DATA
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          5. HOW YOU CAN EXERCISE YOUR PRIVACY RIGHTS
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          6. AGE LIMITATION
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          7. INTERNATIONAL DATA TRANSFERS
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          8. CHANGES TO THIS PRIVACY POLICY
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          9. CALIFORNIA PRIVACY RIGHTS
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          10. DATA RETENTION
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          11. HOW “DO NOT TRACK” REQUESTS ARE HANDLED
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          12. PERSONAL DATA CONTROLLER
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          13. CONTACT US
        </p>
        <br/>
        <p dir="ltr">
          1. CATEGORIES OF PERSONAL DATA WE COLLECT
        </p>
        <br/>
        <p dir="ltr">
          We collect data you give us voluntarily (for example, when you enter your
          gender, age category, email). We also collect data automatically (for
          example, your IP address). We also may receive data about you from third
          parties.
        </p>
        <br/>
        <p dir="ltr">
          1.1. Data you give us
        </p>
        <br/>
        <p dir="ltr">
          You provide us information about yourself in the process of ordering
          personalized math solution. For example: gender, age category, email
          address.
        </p>
        <br/>
        <p dir="ltr">
          1.2. Data we collect automatically:
        </p>
        <br/>
        <p dir="ltr">
          1.2.1. Data about how you found us
        </p>
        <br/>
        <p dir="ltr">
          We collect data about your referring app or URL (that is, the app or place
          on the web where you were when you tapped on our ad).
        </p>
        <br/>
        <p dir="ltr">
          1.2.2. Device and Location data.
        </p>
        <br/>
        <p dir="ltr">
          We collect data from your device. Examples of such data include: language
          settings, IP address, time zone, type and model of a device, device
          settings, operating system and its version, Internet service provider,
          mobile carrier, hardware ID, and Facebook ID.
        </p>
        <br/>
        <p dir="ltr">
          1.2.3. Usage data
        </p>
        <br/>
        <p dir="ltr">
          We record how you interact with our Service. For example, we log your
          taps/clicks on certain areas of the interface, the features, and content
          you interact with, how often you use the Service, how long you are in the
          Service, and your purchases. We also record the ads on our Website with
          which you interact (and the Internet links to which those ads lead).
        </p>
        <br/>
        <p dir="ltr">
          1.2.4. Advertising IDs
        </p>
        <br/>
        <p dir="ltr">
          We collect your Apple Identifier for Advertising (“IDFA”) or Google
          Advertising ID (“AAID”) (depending on the operating system of your device).
          You can typically reset
        </p>
        <br/>
        <p dir="ltr">
          these numbers through the settings of your device’s operating system (but
        </p>
        <br/>
        <p dir="ltr">
          we do not control this).
        </p>
        <br/>
        <p dir="ltr">
          1.2.5. Transaction data
        </p>
        <br/>
        <p dir="ltr">
          When you make payments through the Service, you need to provide financial
          account data, such as your credit card number, to our third-party service
          providers. We do not collect or store full credit card number data, though
          we may receive credit card-related data, data about the transaction,
          including: date, time and amount of the transaction, the type of payment
          method used
        </p>
        <br/>
        <p dir="ltr">
          1.2.6. Cookies
        </p>
        <br/>
        <p dir="ltr">
          A cookie is a small text file that is stored on a user's computer for
          record-keeping purposes. Cookies can be either session cookies or
          persistent cookies. A session cookie expires when you close your browser
          and is used to make it easier for you to navigate our Service. A persistent
          cookie remains on your hard drive for an extended period of time. We also
          use tracking pixels that set cookies to assist with delivering online
          advertising.
        </p>
        <br/>
        <p dir="ltr">
          Cookies are used, in particular, to automatically recognize you the next
          time you visit our Website. As a result, the information, which you have
          earlier entered in certain fields on the Website may automatically appear
          the next time when you use our Service. Cookie data will be stored on your
          device and most of the times only for a limited time period.
        </p>
        <br/>
        <p dir="ltr">
          2. FOR WHAT PURPOSES WE PROCESS YOUR PERSONAL DATA
        </p>
        <br/>
        <p dir="ltr">
          We process your personal data:
        </p>
        <br/>
        <p dir="ltr">
          2.1. To provide our Service
        </p>
        <br/>
        <p dir="ltr">
          This includes enabling you to use the Service in a seamless manner and
          preventing or addressing Service errors or technical issues.
        </p>
        <br/>
        <p dir="ltr">
          To host personal data and enable our Service to operate and be distributed
          we use Google Cloud, which is a hosting and backend service.
        </p>
        <br/>
        <p dir="ltr">
          2.2. To customize your experience
        </p>
        <br/>
        <p dir="ltr">
          We process your personal data, such as gender, age, books preferences, to
          adjust the content of the Service and make offers tailored to your personal
          preferences. As a result of such processing, we will be able to provide a
          personalized math solution.
        </p>
        <br/>
        <p dir="ltr">
          2.3. To manage your account and provide you with customer support
        </p>
        <br/>
        <p dir="ltr">
          We process your personal data to respond to your requests for technical
          support, service information or to any other communication you initiate.
          This includes accessing your account to address technical support requests.
          For this purpose, we may send you, for example, notifications or emails
          about the performance of our Service, security, payment transactions,
          notices regarding our Terms and Conditions of Use or this Privacy Policy.
        </p>
        <br/>
        <p dir="ltr">
          2.4. To communicate with you regarding your use of our Service
        </p>
        <br/>
        <p dir="ltr">
          We communicate with you, for example, by emails. These may include, for
          example, emails with information about the Service.
        </p>
        <br/>
        <p dir="ltr">
          The services that we use for these purposes may collect data concerning the
          date and time when the message was viewed by the Website’s users, as well
          as when they interacted with it, such as by clicking on links included in
          the message.
        </p>
        <br/>
        <p dir="ltr">
          We use Mailfire, which is a marketing personalization and retention
          platform, to deliver tailored email messages to our users.
        </p>
        <br/>
        <p dir="ltr">
          2.5. To research and analyze your use of the Service
        </p>
        <br/>
        <p dir="ltr">
          This helps us to better understand our business, analyze our operations,
          maintain, improve, innovate, plan, design, and develop the Service and our
          new products. We also use such data for statistical analysis purposes, to
          test and improve our offers. This enables us to better understand what
          features and sections of the Website our users like more, what categories
          of users use our Website. As a consequence, we often decide how to improve
          the Website based on the results obtained from this processing.
        </p>
        <br/>
        <p dir="ltr">
          To perform research and analysis about how users interact with our Service
          we use Appsflyer. Appsflyer enables us to understand, in particular, how
          users find us (for example, who was the advertiser that delivered an ad to
          users, which led you to our Service). Appsflyer also provides us with
          different analytics tools that enable us to research and analyze use of the
          Service. Privacy Policy Opt Out.
        </p>
        <br/>
        <p dir="ltr">
          We use Facebook Analytics, which is a service provided by Facebook that
          allows us to use different analytical tools. On Facebook Analytics we get,
          in particular, aggregated demographics and insights on how many people
          access the Service, how often users make purchases and other users’
          interactions within the Service. Privacy Policy
        </p>
        <br/>
        <p dir="ltr">
          To analyse how visitors use our Service and to measure effectiveness of
          some ads we use Google Analytics, a web analysis program of Google. In
          order to provide us with analytics, Google Analytics places cookies on your
          device. On Google Analytics we get, in particular, aggregated information
          on the data you enter on our Service and users’ interactions within the
          Service. Google allows you to influence the collection and processing of
          information generated by the Google, in particular, by installing a browser
          plug-in available here
        </p>
        <br/>
        <p dir="ltr">
          We also use Amplitude, which is an analytics service provided by Amplitude
          Inc. We use this tool to understand how customers use our Service.
          Amplitude collects various technical information, in particular, time zone,
          type of device (phone or tablet), unique identifiers (such as IDFA).
          Amplitude also allows us to track various interactions (entering your
          reading goals) that occur on the App or the Website. As a result, Amplitude
          helps us to decide what features should we focus on. Amplitude is EU-US
          Privacy Shield certified. Amplitude provides more information on how they
          process data in it's Privacy Policy
        </p>
        <br/>
        <p dir="ltr">
          To track and analyze behavior of our Service’s users (in particular, how
          they react to changes of the App’s or Website’s structure, text or any
          other component), we use Firebase Remote Config. Firebase Remote Config is
          an A/B testing and configuration service provided by Google.Privacy Policy
          and Privacy and Security in Firebase.
        </p>
        <br/>
        <p dir="ltr">
          We also use Firebase Analytics, which is an analytics service provided by
          Google. In order to understand Google's use of data, consult
          Google'spartner policy. Firebase Privacy information. Google’s Privacy
          Policy.
        </p>
        <br/>
        <p dir="ltr">
          2.6. To send you marketing communications
        </p>
        <br/>
        <p dir="ltr">
          We process your personal data for our marketing campaigns. We may add your
          email address to our marketing list. As a result, you will receive
          information about our products, such as for example, special offers. If you
          do not want to receive marketing emails from us, you can unsubscribe
          following instructions in the footer of the marketing emails. We use
          Mailfire, which is a marketing personalization and retention platform, to
          deliver tailored email messages to our users.
        </p>
        <br/>
        <p dir="ltr">
          2.7. To personalize our ads
        </p>
        <br/>
        <p dir="ltr">
          We and our partners, including Facebook and Google, use your personal data
          to tailor ads and possibly even show them to you at the relevant time. For
          example, if you have accessed our Service, you might see ads of our
          products, in your Facebook’s feed. How to opt out or influence personalized
          advertising
        </p>
        <br/>
        <p dir="ltr">
          iOS: On your iPhone or iPad, go to “Settings,” then “Privacy” and tap
          “Advertising” to select “Limit Ad Track”. In addition, you can reset your
          advertising identifier (this also may help you to see less of personalized
          ads) in the same section.
        </p>
        <br/>
        <p dir="ltr">
          Android: To opt-out of ads on an Android device, simply open the Google
          Settings app on your mobile phone, tap “Ads” and enable “Opt out of
          interest-based ads”. In addition, you can reset your advertising identifier
          in the same section (this also may help you to see less of personalized
          ads).
        </p>
        <br/>
        <p dir="ltr">
          To learn even more about how to affect advertising choices on various
          devices, please look at the information available here.
        </p>
        <br/>
        <p dir="ltr">
          In addition, you may get useful information and opt out of some
          interest-based advertising, by visiting the following links:
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          • Network Advertising Initiative http://optout.networkadvertising.org/
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • Digital Advertising Alliance http://optout.aboutads.info/
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • Digital Advertising Alliance (Canada) http://youradchoices.ca/choices
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • Digital Advertising Alliance (EU) http://www.youronlinechoices.com/
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • DAA AppChoices page http://www.aboutads.info/appchoices
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          Browsers: It is also may be possible to stop your browser from accepting
          cookies altogether by changing your browser’s cookie settings. You can
          usually find these settings in the “options” or “preferences” menu of your
          browser. The following links may be helpful, or you can use the “Help”
          option in your browser.
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          • Cookie settings in Internet Explorer
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • Cookie settings in Firefox
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • Cookie settings in Chrome
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • Cookie settings in Safari web and iOS
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          We value your right to influence the ads that you see, thus we are letting
          you know what service providers we use for this purpose and how some of
          them allow you to control your ad preferences.
        </p>
        <br/>
        <p dir="ltr">
          We use Facebook pixel on the Website. Facebook pixel is a code placed on
          the Website collecting data that helps us track conversions from Facebook
          ads, build targeted audience and remarket to people who have taken some
          action on the Website (for example, purchased a meal plan). We use Facebook
          Ads Manager together with Facebook Custom Audience, which allows us to
          choose audiences that will see our ads on Facebook or other Facebook’s
          products (for example, Instagram). Through Facebook Custom Audience we may
          create a list of users with certain sets of data, such as an IDFA, choose
          users that have completed certain actions in the App (for example,
          installed it). As a result, we may ask Facebook to show some ads to a
          particular list of users. As a result, more of our ads may show up while
          you are using Facebook or other Facebook’s products (for example,
          Instagram). You may learn how to opt out of advertising provided to you
          through Facebook CustomAudience here.
        </p>
        <br/>
        <p dir="ltr">
          Facebook also allows its users to influence the types of ads they see on
          Facebook. To find how to control the ads you see on Facebook, please go
          here or adjust your ads settings on Facebook.
        </p>
        <br/>
        <p dir="ltr">
          Google Ads is an ad delivery service provided by Google that can deliver
          ads to users. In particular, Google allows to us to tailor the ads in way
          that they will appear, for example, only to users that have conducted
          certain actions with our App ( for example, show our ads to users who have
          purchased a subscription).
        </p>
        <br/>
        <p dir="ltr">
          Some other examples of events that may be used for tailoring ads include,
          in particular, installing our App. Google allows its users opt out of
          Google’s personalized ads and to prevent their data from being used by
          Google Analytics.
        </p>
        <br/>
        <p dir="ltr">
          We also use Snapchat Advertising Platform together with Snapchat Audience
          Based Match, which is an ad delivery service provided by Snapchat that can
          link the activity of some users of our Website with the Snapchat
          advertising network and show some of our ads to them. As a result, you may
          see more of ads on Snapchat in case you use our Website. Snapchat allows
          you to Opt Out of their audience based ads. Privacy Policy.
        </p>
        <br/>
        <p dir="ltr">
          2.8. To process your payments
        </p>
        <br/>
        <p dir="ltr">
          We provide paid products and/or services within the Service. For this
          purpose, we use third-party services for payment processing (for example,
          payment processors). As a result of this processing, you will be able to
          make a payment for a subscription and we will be notified that the payment
          has been made. We will not store or collect your payment card details
          ourselves. This information will be provided directly to our third-party
          payment processors.
        </p>
        <br/>
        <p dir="ltr">
          2.9. To enforce our Terms and Conditions of Use and to prevent and combat
          fraud
        </p>
        <br/>
        <p dir="ltr">
          We use personal data to enforce our agreements and contractual commitments,
          to detect, prevent, and combat fraud. As a result of such processing, we
          may share your information with others, including law enforcement agencies
          (in particular, if a dispute arises in connection with our Terms and
          Conditions of Use).
        </p>
        <br/>
        <p dir="ltr">
          2.10. To comply with legal obligations
        </p>
        <br/>
        <p dir="ltr">
          We may process, use, or share your data when the law requires it, in
          particular, if a law enforcement agency requests your data by available
          legal means.
        </p>
        <br/>
        <p dir="ltr">
          3. UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA (Applies only to
          EEA-based users)
        </p>
        <br/>
        <p dir="ltr">
          In this section, we are letting you know what legal basis we use for each
          particular purpose of processing. For more information on a particular
          purpose, please refer to Section 2. This section applies only to EEA-based
          users.
        </p>
        <br/>
        <p dir="ltr">
          We process your personal data under the following legal bases:
        </p>
        <br/>
        <p dir="ltr">
          3.1. to perform our contract with you;
        </p>
        <br/>
        <p dir="ltr">
          Under this legal basis we:
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          • Provide our Service (in accordance with our Terms and Conditions of Use)
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • Customize your experience
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • Manage your account and provide you with customer support
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • Communicate with you regarding your use of our Service
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • Process your payments
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          3.2. for our (or others') legitimate interests, unless those interests are
          overridden by your interests or fundamental rights and freedoms that
          require protection of personal data;
        </p>
        <br/>
        <p dir="ltr">
          We rely on legitimate interests:
        </p>
        <br/>
        <p dir="ltr">
          • to communicate with you regarding your use of our Service
        </p>
        <br/>
        <p dir="ltr">
          The legitimate interest we rely on for this purpose is our interest to
          encourage you to use our Service more often. We also take into account the
          potential benefits to you, which may help you to advance in study and
          career.
        </p>
        <br/>
        <p dir="ltr">
          • to research and analyze your use of the Service
        </p>
        <br/>
        <p dir="ltr">
          Our legitimate interest for this purpose is our interest in improving our
          Service so that we understand users’ preferences and are able to provide
          you with a better experience (for example, to make the use of the Website
          easier and more enjoyable, or to introduce and test new features).
        </p>
        <br/>
        <p dir="ltr">
          • to send you marketing communications
        </p>
        <br/>
        <p dir="ltr">
          The legitimate interest we rely on for this processing is our interest to
          promote our Service in a measured and appropriate way.
        </p>
        <br/>
        <p dir="ltr">
          • to personalize our ads
        </p>
        <br/>
        <p dir="ltr">
          The legitimate interest we rely on for this processing is our interest to
          promote our Service in a reasonably targeted way.
        </p>
        <br/>
        <p dir="ltr">
          • to enforce our Terms and Conditions of Use and to prevent and combat
          fraud
        </p>
        <br/>
        <p dir="ltr">
          Our legitimate interests for this purpose are enforcing our legal rights,
          preventing and addressing fraud and unauthorised use of the Service, non-
          compliance with our Terms and Conditions of Use.
        </p>
        <br/>
        <p dir="ltr">
          3.3. to comply with legal obligations.
        </p>
        <br/>
        <p dir="ltr">
          4. WITH WHOM WE SHARE YOUR PERSONAL DATA
        </p>
        <br/>
        <p dir="ltr">
          We share information with third parties that help us operate, provide,
          improve, integrate, customize, support, and market our Service. We may
          share some sets of personal data, in particular, for purposes indicated in
          Section 2 of this Privacy Policy. The types of third parties we share
          information with include, in particular:
        </p>
        <br/>
        <p dir="ltr">
          4.1. Service providers
        </p>
        <br/>
        <p dir="ltr">
          We share personal data with third parties that we hire to provide services
          or perform business functions on our behalf, based on our instructions. We
          share your personal information with the following types of service
          providers:
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          • cloud storage providers (Google)
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • data analytics providers (Facebook, Google, Appsflyer, Firebase,
          Amplitude)
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • measurement partners
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • marketing partners (in particular, social media networks, marketing
          agencies, email delivery services, Facebook, Google, Snapchat)
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • payment processing providers
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <p dir="ltr">
          • communication services providers (Mailfire)
        </p>
        <br/>
        <br/>
        <p dir="ltr">
          4.2. Law enforcement agencies and other public authorities
        </p>
        <br/>
        <p dir="ltr">
          We may use and disclose personal data to enforce our Terms and Conditions
          of Use, to protect our rights, privacy, safety, or property, and/or that of
          our affiliates, you or others, and to respond to requests from courts, law
          enforcement agencies, regulatory agencies, and other public and government
          authorities, or in other cases provided for by law.
        </p>
        <br/>
        <p dir="ltr">
          4.3. Third parties as part of a merger or acquisition
        </p>
        <br/>
        <p dir="ltr">
          As we develop our business, we may buy or sell assets or business
          offerings. Customers’ information is generally one of the transferred
          business assets in these types of transactions. We may also share such
          information with any affiliated entity (e.g. parent company or subsidiary)
          and may transfer such information in the course of a corporate transaction,
          such as the sale of our business, a divestiture, merger, consolidation, or
          asset sale, or in the unlikely event of bankruptcy.
        </p>
        <br/>
        <p dir="ltr">
          5. HOW YOU CAN EXERCISE YOUR PRIVACY RIGHTS
        </p>
        <br/>
        <p dir="ltr">
          To be in control of your personal data, you have the following rights:
          Accessing / reviewing / updating / correcting your personal data. You may
          request a copy of your personal data and request us to update/correct your
          personal data collected during your use of the Service at
          support@mathmaster.me
        </p>
        <br/>
        <p dir="ltr">
          Deleting your personal data. You can request erasure of your personal data
          by sending us an email at support@mathmaster.me
        </p>
        <br/>
        <p dir="ltr">
          When you request deletion of your personal data, we will use reasonable
          efforts to honor your request. In some cases we may be legally required to
          keep some of the data for a certain time; in such event, we will fulfill
          your request after we have complied with our obligations. Objecting to or
          restricting the use of your personal data. You can ask us to stop using all
          or some of your personal data or limit our use thereof by sending a request
          at support@mathmaster.me
        </p>
        <br/>
        <p dir="ltr">
          Additional information for EEA-based users.
        </p>
        <br/>
        <p dir="ltr">
          If you are based in the EEA, you have the following rights in addition to
          the above:
        </p>
        <br/>
        <p dir="ltr">
          The right to lodge a complaint with supervisory authority. We would love
          you to contact us directly, so we could address your concerns.
          Nevertheless, you have the right to lodge a complaint with a competent data
          protection supervisory authority, in particular in the EU Member State
          where you reside, work or where the alleged infringement has taken place.
        </p>
        <br/>
        <p dir="ltr">
          The right to data portability. If you wish to receive your personal data in
          a machine-readable format, you can send respective request at
          support@mathmaster.me
        </p>
        <br/>
        <p dir="ltr">
          6. AGE LIMITATION
        </p>
        <br/>
        <p dir="ltr">
          We do not knowingly process personal data from persons under 16 years of
          age. If you learn that anyone younger than 16 has provided us with personal
          data, please contact us at support@mathmaster.me
        </p>
        <br/>
        <p dir="ltr">
          7. INTERNATIONAL DATA TRANSFERS
        </p>
        <br/>
        <p dir="ltr">
          We do business globally. We may transfer personal data to countries other
          than the country in which the data was originally collected in order to
          provide the Service set forth in the Terms and Conditions of Use and for
          purposes indicated in this Privacy Policy. If these countries do not have
          the same data protection laws as the country in which you initially
          provided the information, we deploy special safeguards. In particular, if
          we transfer personal data originating from the EEA to countries with not
          adequate level of data protection, we use one of the following legal bases:
          (i) Standard Contractual Clauses approved by the European Commission , or
          (ii) the EU-U.S. Privacy Shield Framework or (iii) the European Commission
          adequacy decisions about certain countries.
        </p>
        <br/>
        <p dir="ltr">
          8. CHANGES TO THIS PRIVACY POLICY
        </p>
        <br/>
        <p dir="ltr">
          We may modify this Privacy Policy from time to time. If we decide to make
          material changes to this Privacy Policy, you will be notified through our
          Service or by other available means and will have an opportunity to review
          the revised Privacy Policy. By continuing to access or use the Service
          after those changes become effective, you agree to be bound by the revised
          Privacy Policy.
        </p>
        <br/>
        <p dir="ltr">
          9. CALIFORNIA PRIVACY RIGHTS
        </p>
        <br/>
        <p dir="ltr">
          California’s Shine the Light law gives California residents the right to
          ask companies once a year what personal information they share with third
          parties for those third parties' direct marketing purposes. Learn more
          about what is considered to be personal information under the statute.
        </p>
        <br/>
        <p dir="ltr">
          To obtain this information from us, please send an email message to
          support@mathmaster.me, which includes “Request for California Shine the
          Light Privacy Information” on the subject line and your state of residence
          and email address in the body of your message. If you are a California
          resident, we will provide the requested information to you at your email
          address in response.
        </p>
        <br/>
        <p dir="ltr">
          10. DATA RETENTION
        </p>
        <br/>
        <p dir="ltr">
          We will store your personal data for as long as it is reasonably necessary
          for achieving the purposes set forth in this Privacy Policy (including
          providing the Service to you), which includes (but is not limited to) the
          period during which you have an account with the Service. We will also
          retain and use your personal data as necessary to comply with our legal
          obligations, resolve disputes, and enforce our agreements.
        </p>
        <br/>
        <p dir="ltr">
          11. HOW “DO NOT TRACK” REQUESTS ARE HANDLED
        </p>
        <br/>
        <p dir="ltr">
          This Service does not support “Do Not Track” requests. To determine whether
          any of the third- party services it uses honor the “Do Not Track” requests,
          please read their privacy policies.
        </p>
        <br/>
        <p dir="ltr">
          12. PERSONAL DATA CONTROLLER
        </p>
        <br/>
        <p dir="ltr">
          Starnest Services Limited, a company with its address at Vasili Michailidi,
          9, 3026, Limassol, Cyprus, will be the controller of your personal data.
        </p>
        <br/>
        <p dir="ltr">
          13. CONTACT US
        </p>
        <br/>
        <p dir="ltr">
          You may contact us at any time for details regarding this Privacy Policy
          and its previous versions. For any questions concerning your account or
          your personal data please contact us at support@mathmaster.me
        </p>
        <br/>
        <p dir="ltr">
          Effective as of: 21 July 2021
        </p>
        <br/>
      </div>
    </div>
    <div class="py-4">
      <routerLink to="/plans">
        Back
      </routerLink>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
};
</script>

<style scoped>

</style>
